<template>
	<div class="app-container" style="background-color: #fff;padding-bottom: 50px;min-height: 700px;">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="活动名称" prop="activeName">
				<el-input disabled v-model="ruleForm.activeName" placeholder="最多输入20个字" maxlength="20" style="width:400px"></el-input>
			</el-form-item>
			<el-form-item label="活动时间" required prop="activityTime">
				<el-date-picker disabled v-model="ruleForm.activityTime" type="datetimerange" :picker-options="pickerOptions"
				range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			<!-- <el-form-item label="时段" required prop="timeId">
				<el-select v-model="ruleForm.timeId" filterable clearable style="width: 200px;margin-right: 10px;">
					<el-option v-for="item in timeList" :key="item.timeId" :label="item.timeValue" :value="item.timeId">
					</el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item label="添加商品" required >
				<el-button :disabled="type==1" type="primary" @click="choseActive" style="margin-bottom: 20px;">选择商品</el-button>
				<el-table :data="activeGoodsList" style="width: 100%;margin-bottom: 10px;">
					<el-table-column label="商品" width="300">
						<template slot-scope="scope">
							<div class="product-info">
								<img v-if="scope.row.skuImgUrl" :src="scope.row.skuImgUrl" />
								<svg-icon v-else icon-class="noImgIcon" />
								<div>
									<div>
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.goodsName}}</pre>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="originalPrice" label="划线价格"></el-table-column>
					<el-table-column prop="salePrice" label="销售价格"></el-table-column>
					<el-table-column label="秒杀价格">
						<template scope="scope">
							<div style="display:flex;justify-content:flex-start;align-items:center;">
								<div style="white-space: nowrap;margin-right: 5px;color:#F56C6C;">￥{{scope.row.secKillPrice}}</div>
								<buttonPermissions :datas="'updateGoodsPrice'">
									<i class="el-icon-edit-outline" @click="goodsEdit(scope.row,1)"></i>
								</buttonPermissions>
							</div>
							<!-- <el-input v-model="scope.row.secKillPrice" size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input> -->
						</template>
					</el-table-column>
					<el-table-column label="限购数量">
						<template scope="scope">
							<el-input onkeyup="this.value=this.value.replace(/\D/g,'')" :disabled="type==1" v-model="scope.row.quotaNum" size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<span v-if="type!=1" style="color:#F56C6C;cursor:pointer;" @click="deleteChose(scope.row)">删除</span>
						</template>
					</el-table-column>
				</el-table>
			</el-form-item>
		</el-form>
		<div class="bottom-save-btn">
			<el-button style="width: 150px;" @click="handleCancel">取消</el-button>
			<el-button style="width: 150px;margin-left: 40px;" type="primary" @click="saveBtn" :disabled='loading' :loading="loading">保存</el-button>
		</div>
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1400px" class="dialog">
			<select-produce api="activitycouponChoseproductlist" :params="['goodsName', 'barCode']" :selectedData="activeGoodsList"
			@getSelectList="getSelectList" :visible.sync="addProductDialogShow" v-if="addProductDialogShow"></select-produce>
		</el-dialog>
		<el-dialog title="设置秒杀价格和库存" :visible.sync="editProductDialogShow" width="1400px" class="dialog">
			<el-tabs :disabled="type==1" type="border-card" v-model="editableTabsValue">
				<el-tab-pane label="选择商品规格" name="1">
					<el-table :data="skuList" style="width: 100%;" :row-key="getRowKeys" @selection-change="handleSelectionChange" ref="tab">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column prop="specValues" label="规格"></el-table-column>
						<el-table-column prop="salePrice" label="价格"></el-table-column>
						<el-table-column prop="stock" label="现有库存"></el-table-column>
						<el-table-column label="操作" width="120">
							<template slot-scope="scope">
								<div v-if="!scope.row.hasSecKill" class="table-button" style="background-color: #409EFF;border-radius: 5px;width: 90px;color: #fff;text-align: center;height: 35px;line-height: 35px;"
								@click="joinSkill(scope.row,1)">参加秒杀</div>
								<div v-else class="table-button" style="border-radius: 5px;width: 90px;color: #999;text-align: center;height: 35px;line-height: 35px;">已参加</div>
							</template>
						</el-table-column>
					</el-table>
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						<el-checkbox @change="tabCheckedChange" v-model="checked">全选</el-checkbox>
						<el-button style="margin-left: 20px;" type="text" :disabled="type==1" @click="joinSkill({},2)">批量参加</el-button>
					</div>
					<div style="display: flex;justify-content: flex-end;">
						<el-button style="margin-right: 20px;width: 80px;" type="primary" @click="toNext">下一步</el-button>
					</div>
				</el-tab-pane>
				<el-tab-pane label="设置价格和库存" name="2">
					<el-table :data="checkSkuList" style="width: 100%;" :row-key="getRowKeys2" @selection-change="handleSelectionChange2"
					ref="tabSet">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column prop="specValues" label="规格"></el-table-column>
						<!-- <el-table-column prop="salePrice" label="价格"></el-table-column> -->
						<!-- <el-table-column prop="stock" label="库存"></el-table-column> -->
						<el-table-column label="秒杀价格">
							<template scope="scope">
								<div>
									<el-input onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" v-model="scope.row.secKillPrice" size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
									<div style="margin-top: 10px;margin-left: 10px;">价格(元)：{{scope.row.salePrice}}</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="秒杀库存">
							<template scope="scope">
								<div>
									<el-input onkeyup="this.value=this.value.replace(/\D/g,'')" :disabled="type==1" v-model="scope.row.secKillStock" size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
									<div style="margin-top: 10px;margin-left: 10px;">现有库存：{{scope.row.stock}}</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="120">
							<template slot-scope="scope">
								<div v-if="type!=1" class="table-button" style="background-color: #409EFF;border-radius: 5px;width: 90px;color: #fff;text-align: center;height: 35px;line-height: 35px;"
								@click="cancelKillBefore(scope.row,1)">取消参加</div>
							</template>
						</el-table-column>
					</el-table>
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						<el-checkbox @change="tabCheckedChange2" v-model="checked2">全选</el-checkbox>
						<el-button style="margin-left: 20px;" type="text" :disabled="type==1" @click="cancelKillBefore({},2)">批量删除</el-button>
					</div>
					<div style="display: flex;align-items: center;justify-content: space-between;">
						<div style="display: flex;align-items: center;">
							<div style="margin-left: 10px;">批量设置</div>
							<el-button style="margin-left: 20px;" type="text" @click="setPrice">秒杀价格</el-button>
							<el-button style="margin-left: 20px;" type="text" :disabled="type==1" @click="setStock">秒杀库存</el-button>
						</div>
						<el-button style="margin-right: 20px;width: 80px;" type="primary" @click="commitEdit">确定</el-button>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<el-dialog :title="batchTitle" :visible.sync="batchEditDialog" width="450px" class="dialog">
			<div>
				<el-input @input="inputChange" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" v-model="batchKillPrice" class="input-with-select" style="width: 400px;margin: 0 5px;" placeholder="请输入数值"></el-input>
				<div style="display: flex;justify-content: flex-end;margin-top: 20px;">
					<el-button style="margin-left: 20px;" type="warning" @click="batchEditDialog=false">取消</el-button>
					<el-button style="margin-left: 20px;" type="primary" @click="batchEditCommit()">确定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';
	import selectProduce from './selectMultiPro.vue';
	import {
		seckillSkuList,
		seckillGoodsUpdate,
		seckilleditDetail,
		seckillGoodsDel,
		seckillSkuDel
	} from "@/api/goods.js";
	export default {
		components: {
			buttonPermissions,
			selectProduce
		},
		data() {
			return {
				ruleForm: {
					activeName: '',
					activityTime: '',
				},
				activeGoodsList: [],
				rules: {
					activeName: [{
						required: true,
						message: '请填写活动名称',
						trigger: 'change'
					}],
					activityTime: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
					// timeId: [{
					// 	required: true,
					// 	message: '请选择活动时间段',
					// 	trigger: 'change'
					// }],
					// activeGoodsList: [{
					// 	type: 'array',
					// 	required: true,
					// 	message: '请选择活动商品',
					// 	trigger: 'change'
					// }]
				},
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				timeList: [{
					timeId: 1,
					timeValue: '1点'
				}],
				addProductDialogShow: false,
				loading: false,
				editProductDialogShow: false,
				editableTabsValue: '1',
				currentEditGoods: null,
				atyId: 0, //活动id
				skuList: [], //全部规格列表
				checkSkuList: [], //选择的规格列表
				checked: false,
				checked2: false,
				multipleSelection: [],
				multipleSelection2: [],
				batchEditDialog: false,
				batchEditType: 1, //1 批量设置价格 2批量设置库存
				batchKillPrice: '',
				batchTitle: '批量设置',
				isEdit:false,
				baseData:[],
				type:0
			}
		},
		beforeMount() {
			this.atyId = this.$route.query.id || 0;
			this.type = this.$route.query.type||0;
			// this.ruleForm.activeName = this.$route.query.atyName || '';
			// this.ruleForm.activityTime = this.$route.query.atyTime || '';
			this.initData();
		},
		methods: {
			async initData(){
				let data = {
					secKillId:+this.atyId
				};
				let res = await seckilleditDetail(data);
				if(res.success){
					this.ruleForm.activeName = res.data.activityName;
					this.ruleForm.activityTime = [res.data.startTime,res.data.endTime];
					this.activeGoodsList = res.data.goodsList;
					this.baseData = res.data.goodsList;
				}else{
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			},
			getSelectList(data) {
				this.currentEditGoods = data[0];
				this.seckillSkuList();
			},
			choseActive() {
				this.isEdit = false;
				this.editableTabsValue = "1";
				this.addProductDialogShow = true;
			},
			handleCancel() {
				this.$router.push({
					path: '/market/secondKill/index'
				});
			},
			saveBtn() {
				this.loading = true;
				console.log("--------",this.activeGoodsList);
				if(!this.activeGoodsList.length){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择活动商品'
					});
					return;
				}
				var isNoLimitNum=false;
				this.activeGoodsList.map(item=>{
					console.log("------",item.quotaNum);
					if(typeof(item.quotaNum)=='undefined' || item.quotaNum.length==0){
						isNoLimitNum = true;
					}
					return item;
				})
				if(isNoLimitNum){
					this.$message({
						showClose: true,
						type: 'error',
						message: '有商品限购数量设置错误'
					});
					return;
				}
				this.seckillGoodsUpdate();
			},
			async seckillSkuList() {
				let data = {
					goodsId: this.currentEditGoods.goodsId,
					secKillId:this.atyId
				};
				let res = await seckillSkuList(data);
				if (res.success) {
					// res.data.map(item => {
					// 	item.isJoin = false;
					// 	return item;
					// })
					this.skuList = res.data;
					this.checkSkuList = [];
					this.skuList.map(item=>{
						if(item.hasSecKill){
							this.checkSkuList.push(item);
						}
						return item;
					})
					this.addProductDialogShow = false;
					let that = this;
					setTimeout(function() {
						that.editProductDialogShow = true;
					}, 500)
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			},
			async seckillGoodsUpdate() {
				this.activeGoodsList.map(item=>{
					item.quotaNum = parseInt(item.quotaNum);
					return item;
				})
				let data = {
					secKillId:this.atyId,
					goodsRequestList: this.activeGoodsList
				};
				let res = await seckillGoodsUpdate(data);
				if (res.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: "保存成功"
					});
					this.$router.push({
						path: '/market/secondKill/index'
					});
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
				this.loading = false;
			},
			getRowKeys(row) {
				return row.skuId;
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				var isAll = val.length == this.skuList.length ? true : false;
				if (isAll) {
					this.checked = true;
				} else {
					this.checked = false;
				}
			},
			getRowKeys2(row) {
				return row.skuId;
			},
			handleSelectionChange2(val) {
				this.multipleSelection2 = val;
				var isAll = val.length == this.checkSkuList.length ? true : false;
				if (isAll) {
					this.checked2 = true;
				} else {
					this.checked2 = false;
				}
			},
			tabCheckedChange() {
				this.$refs['tab'].toggleAllSelection();
			},
			tabCheckedChange2() {
				this.$refs['tabSet'].toggleAllSelection();
			},
			//加入
			joinSkill(row, type) {
				if (type == 1) {
					this.skuList.map(item => {
						if (item.skuId == row.skuId) {
							item.hasSecKill = true;
						}
						return item;
					})
					row.secKillPrice=null;
					row.secKillStock=null;
					this.checkSkuList.push(row);
				} else {
					this.skuList.map(item => {
						this.multipleSelection.map(items => {
							if (item.skuId == items.skuId) {
								item.hasSecKill = true;
							}
							return items;
						})
						return item;
					})
					this.multipleSelection.map(item=>{
						item.secKillPrice=null;
						item.secKillStock=null;
						return item;
					})
					this.checkSkuList = this.multipleSelection;
				}
				this.$refs.tab.clearSelection();
			},
			
			async deletSku(row, type){
				let ids = [];
				if(type==1){
					if(row.secKillSkuId){
						ids.push(row.secKillSkuId)
					}
				}else{
					this.multipleSelection2.map(item=>{
						if(item.secKillSkuId){
							ids.push(item.secKillSkuId);
						}
						return item;
					})
				}
				if(ids.length){
					let data = {
						secKillSkuIds:ids
					};
					let res = await seckillSkuDel(data);
					if(res.code==200){
						if(res.success){
							this.$message({
								showClose: true,
								type: 'success',
								message: '取消成功'
							});
							this.clearKill(row, type)
						}else{
							this.$message({
								showClose: true,
								type: 'error',
								message: res.alertMsg
							});
						}
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg
						});
					}
				}else{
					this.clearKill(row, type);
					this.$message({
						showClose: true,
						type: 'success',
						message: '取消成功'
					});
				}
				
			},
			cancelKillBefore(row, type){
				if(this.checkSkuList.length<=1){
					this.$message({
						showClose: true,
						type: 'warning',
						message: '至少选择一个规格'
					});
					return;
				}
				if(this.isEdit){
					this.deletSku(row, type);
				}else{
					this.clearKill(row, type);
				}
			},
			//取消参加
			clearKill(row, type) {
				if (type == 1) {
					var list = [];
					this.checkSkuList.map(item => {
						if (item.skuId != row.skuId) {
							list.push(item);
						}
						return item;
					})
					this.checkSkuList = list;
					
					this.skuList.map(item => {
						if (item.skuId == row.skuId) {
							item.hasSecKill = false;
						}
						return item;
					})
				} else {

					// this.checkSkuList.map((item, index) => {
					// 	this.multipleSelection2.map(items => {
					// 		if (item.skuId == items.skuId) {
					// 			this.checkSkuList.slice(index, 1)
					// 		}
					// 		return items;
					// 	})
					// 	return item;
					// })
					var ids1 = [];
					var ids2 = [];
					this.multipleSelection2.map(item=>{
						ids1.push(item.skuId);
						return item;
					})
					this.checkSkuList.map(item=>{
						ids2.push(item.skuId);
						return item;
					})
					let arr = ids2.filter(function(val) {
						return ids1.indexOf(val) === -1;
					})
					
					var leaveSku = [];
					this.checkSkuList.map(item => {
						arr.map(items=>{
							if(item.skuId == items){
								leaveSku.push(item);
							}
							return items;
						})
						return item;
					})
					console.log("---leaveSku---",leaveSku);
					this.checkSkuList = leaveSku;
					
					this.skuList.map(item => {
						this.multipleSelection2.map(items=>{
							if(item.skuId == items.skuId){
								item.hasSecKill = false;
							}
							return items;
						})
						return item;
					})
				}
				if (!this.checkSkuList.length) {
					this.checked2 = false;
				}
				this.$refs.tabSet.clearSelection();
			},
			toNext() {
				if (!this.checkSkuList.length) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请选择要参加活动的规格'
					});
					return;
				}
				this.editableTabsValue = '2';
			},
			commitEdit() {
				// if(!this.checkSkuList.length){
				// 	this.$message({
				// 		showClose: true,
				// 		type: 'warning',
				// 		message: '请选择要参加活动的规格'
				// 	});
				// 	return;
				// }
				var isSetPrice = true;
				var isSetStock = true;
				this.checkSkuList.map(item => {
					if (!item.secKillStock) {
						isSetStock = false;
					}
					if (!item.secKillPrice) {
						isSetPrice = false;
					}
					return item;
				})
				
				if(!isSetPrice || !isSetStock){
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请填写秒杀价和秒杀库存'
					});
					return;
				}
				
				var isOverStock = false;
				this.checkSkuList.map(item => {
					if (item.stock < item.secKillStock) {
						isOverStock = true;
					}
					return item;
				})
				
				if(isOverStock){
					this.$message({
						showClose: true,
						type: 'warning',
						message: '有规格库存不足，请重新设置'
					});
					return;
				}
				var minPrice = 0;
				this.checkSkuList.map((item,index)=>{
					item.secKillPrice = (+item.secKillPrice).toFixed(2);
					var price = +item.secKillPrice;
					if(index==0){
						minPrice=price;
					}else{
						if(price<minPrice){
							minPrice = price;
						}
					}
					return item;
				})
				this.currentEditGoods.skuRequestList = this.checkSkuList;
				this.currentEditGoods.skuList = this.checkSkuList;
				this.currentEditGoods.secKillPrice=minPrice.toFixed(2);
				// this.currentEditGoods.quotaNum='';
				// this.activeGoodsList.push(this.currentEditGoods);
				//判断是否存在
				let indexs = -1;
				this.activeGoodsList.map((item,index)=>{
					if(item.goodsId==this.currentEditGoods.goodsId){
						indexs = index;
					}
					return item;
				})
				if(indexs>-1){
					this.activeGoodsList = this.activeGoodsList.map(item=>{
						if(item.goodsId==this.currentEditGoods.goodsId){
							console.log("--------",item);
							item = this.currentEditGoods;
						}
						return item;
					})
				}else{
					this.activeGoodsList.push(this.currentEditGoods);
				}
				this.editProductDialogShow = false;
				this.editableTabsValue = '1';
				this.skuList = [];
				this.checkSkuList = [];
			},
			setPrice() {
				this.batchTitle = "批量设置秒杀价";
				this.batchKillPrice='';
				this.batchEditType = 1;
				this.batchEditDialog = true;
			},
			setStock() {
				this.batchTitle = "批量设置秒杀库存";
				this.batchKillPrice='';
				this.batchEditType = 2;
				this.batchEditDialog = true;
			},
			inputChange(val){
				if(this.batchEditType == 1){
					if(val<0){
						this.batchKillPrice = (Math.abs(val)).toFixed(2);
					}
				}else if(this.batchEditType == 2){
					var count = val;
					this.batchKillPrice = parseInt(count);
				}
				console.log("---inputChange-----",val)
			},
			batchEditCommit() {
				var str = '';
				if (this.batchEditType == 1 && !this.batchKillPrice) {
					str = '请填写秒杀价';
				} else if (this.batchEditType == 2 && !this.batchKillPrice) {
					str = '请填写秒杀库存';
				} else if (isNaN(this.batchKillPrice)) {
					str = '请填写正确的数值';
				}
				if (str) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: str
					});
					return;
				}
				var tempNum = this.batchKillPrice;
				// if(this.batchEditType!=1){
				// 	tempNum = tempNum.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
				// }
				
				// if (tempNum.indexOf('.') < 0 && tempNum != '') {
				// 	// 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
				// 	tempNum = parseInt(tempNum);
				// }
				if (this.batchEditType == 1) {
					this.checkSkuList.map(item => {
						item.secKillPrice = tempNum;
						return item;
					})
				} else {
					var isOverStock = false;
					this.checkSkuList.map(item => {
						if (item.stock < tempNum) {
							isOverStock = true;
						}
						return item;
					})
					if (isOverStock) {
						this.$message({
							showClose: true,
							type: 'warning',
							message: '有规格库存不足，请重新设置'
						});
					} else {
						this.checkSkuList.map(item => {
							item.secKillStock = tempNum;
							return item;
						})
					}
				}
				this.batchEditDialog = false;
				console.log("---tempNum---", this.checkSkuList);
			},
			//编辑所选商品
			goodsEdit(row){
				this.isEdit = true;
				this.currentEditGoods = row;
				console.log("----row-----",row);
				if(row.skuList&&row.skuList.length){
					this.skuList = row.skuList;
					this.checkSkuList = [];
					this.skuList.map(item=>{
						if(item.hasSecKill){
							this.checkSkuList.push(item);
						}
						return item;
					})
					this.addProductDialogShow = false;
					let that = this;
					setTimeout(function() {
						that.editProductDialogShow = true;
					}, 500)
				}else{
					this.seckillSkuList();
				}
				this.editableTabsValue = "2";
				this.editProductDialogShow = true;
			},
			//删除所选商品
			async deleteChose(row){
				let _this = this;
				this.$confirm('是否确认删除当前商品', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let res = await seckillGoodsDel({
						goodsId:row.goodsId,
						secKillId:_this.atyId
					});
					if(res.code==200){
						if (res.success) {
							_this.$message({showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							var list = [];
							_this.activeGoodsList.map(item=>{
								if(row.goodsId!=item.goodsId){
									list.push(item);
								}
								return item;
							})
							_this.activeGoodsList = list;
						}else{
							this.$message({
								showClose: true,
								type: 'error',
								message: res.alertMsg
							});
						}
					}else if(res.code==500013){
						//新加商品直接删除
						var baselist = [];
						_this.activeGoodsList.map(item=>{
							if(row.goodsId!=item.goodsId){
								baselist.push(item);
							}
							return item;
						})
						_this.activeGoodsList = baselist;
						_this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
					}
				})
			}
		}
	}
</script>

<style lang="less">
	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		margin-left: -20px;
		box-shadow: 13px 1px 6px #999;
	}
</style>
